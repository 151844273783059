import React from "react";

const HexCell = (props: any) => {
    let imgSrc = "assets/Blue.png";
    let imgOpacity = 0;
    let full = false;
    if (props.tile && props.tile.Front !== '') {
        full = true;
        if (props.tile.Flipped) {
            imgSrc = `assets/${props.tile.Back}.png`;
        } else {
            imgSrc = `assets/${props.tile.Front}Block.png`;
        }
        imgOpacity = 1;
    }
    return (
        <div className={"hexCell" + (
            props.first ? 
                " first" : 
                props.last ? 
                    " last" : 
                    props.active ? 
                        props.visible ? 
                            " active" : 
                            " active hidden" : 
                        "")}>
            <div className="hex">
                <img src={imgSrc} style={{opacity: imgOpacity}} onClick={full ? () => {} : () => props.click(props.index)} alt='' />
            </div>
        </div>
    );
};

export default HexCell;