const HelpArea = (props: any) => {
    return (
        <div className="center" style={{display:"block", clear:"both", textAlign:"left", color:"black", width: 570}}>
            The top of the screen is the board. The bottom 2 rows are your hand. <br />
            At the start of the game, you only have one tile in your hand. <br />
            As the game progresses, you will get more tiles in your hand. <br />
            At the bottom of each tile in your hand is a preview of what's on the back of the tile. <br />
            The currently selected tile is outlined in a black square. <br />
            Once you have more than one tile in your hand, click a tile to make it the selected tile. <br />
            Click on any empty cell on the board to place the selected tile. <br />
            When 3 or more tiles are grouped together of the same color, a match is resolved. <br />
            All tiles in the match with a bee on them will flip over. <br />
            All tiles in the match without a bee will be removed from the board. <br />
            Tiles that don't have a back are removed the first time they're matched. <br />
            The goal of the game is to clear the board. <br />
            Making a match of more than 3 will increase your hand size. <br />
            Making a combo match (made when a tile flips and makes a new match) will increase your hand size. <br />
            Tiles are drawn automatically. If all 66 tiles have been drawn, you will be able to continue to play, and you will see empty slots show up in your hand. <br />
            If you play all of your tiles without clearing the board, you lost. <br />
            If the board is ever full, you lost. <br />
            If you are able to clear the board, you win. <br />
            If you win, you will be given a score: <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 point for each tile that was not played <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-1 point for every available slot in your hand <br />
        </div>
    );
};

export default HelpArea;