const Game: GameModel = require('../assets/json/Games.json');
const AllTiles: TileModel[] = require('../assets/json/Tiles.json');

interface GameModel {
    Tiles: number[],
}

interface CellModel {
    CurrentTile: number,
    CurrentTileFlipped: boolean,
    AdjacentTiles: number[],
    Color: string
}

export interface TileModel {
    Front: string,
    Back: string,
    Flipped: boolean
}

interface ControllerModel {
    Tiles: TileModel[],
    AllCells: CellModel[],
    CurrentlyActive: number[],
    ToBeActive: number[],
    LastMatchLength: number,

    LoadTodaysGame: () => void,
    ScoreMatch: (idx: number) => boolean,
    GetChain: (cell: CellModel, existing: CellModel[]) => CellModel[],
    GetAdjacentMatchingCells: (cell: CellModel) => CellModel[]
}

export var BlueTile: TileModel = {
    Front: 'Blue1',
    Back: 'Blue1',
    Flipped: false
}
export var OrangeTile: TileModel = {
    Front: 'Orange1',
    Back: 'Orange1',
    Flipped: false
}
export var RedTile: TileModel = {
    Front: 'Red1',
    Back: 'Red1',
    Flipped: false
}
export var PurpleTile: TileModel = {
    Front: 'Purple1',
    Back: 'Purple1',
    Flipped: false
}
export var YellowTile: TileModel = {
    Front: 'Yellow1',
    Back: 'Yellow1',
    Flipped: false
}
export var GreenTile: TileModel = {
    Front: 'Green1',
    Back: 'Green1',
    Flipped: false
}


export var Controller: ControllerModel = {
    Tiles: [],
    AllCells: [
        { CurrentTile: 0, CurrentTileFlipped: false, AdjacentTiles: [1,2,4], Color: 'Blue' },
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [0,3,4,6] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [0,4,5,7] , Color: ''},
        { CurrentTile: 1, CurrentTileFlipped: false, AdjacentTiles: [1,6,8] , Color: 'Green'},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [0,1,2,6,7,9] , Color: ''},
        { CurrentTile: 2, CurrentTileFlipped: false, AdjacentTiles: [2,7,10] , Color: 'Purple'},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [1,3,4,8,9,11] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [2,4,5,9,10,12] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [3,6,11,13] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [4,6,7,11,12,14] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [5,7,12,15] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [6,8,9,13,14,16] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [7,9,10,14,15,17] , Color: ''},
        { CurrentTile: 3, CurrentTileFlipped: false, AdjacentTiles: [8,11,16] , Color: 'Yellow'},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [9,11,12,16,17,18] , Color: ''},
        { CurrentTile: 4, CurrentTileFlipped: false, AdjacentTiles: [10,12,17] , Color: 'Red'},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [11,13,14,18] , Color: ''},
        { CurrentTile: -1, CurrentTileFlipped: false, AdjacentTiles: [12,14,15,18] , Color: ''},
        { CurrentTile: 5, CurrentTileFlipped: false, AdjacentTiles: [14,16,17] , Color: 'Orange'},
    ],
    CurrentlyActive: [],
    ToBeActive: [],
    LastMatchLength: 0,

    LoadTodaysGame() {
        let duplicateTile = (tile: TileModel) => {
            return {
                Front: tile.Front,
                Back: tile.Back,
                Flipped: tile.Flipped ? true : false
            }
        };
        if (this.Tiles.length === 0) {
            Game.Tiles.forEach(tile => {
                this.Tiles.push(duplicateTile(AllTiles[tile]));
            });
            this.Tiles = [...this.Tiles];
        }
    },

    ScoreMatch(idx) {
        let cell = this.AllCells[idx];
        var currentChain: CellModel[] = [];
        currentChain = this.GetChain(cell, currentChain);
        if (currentChain.length > 2)
        {
            this.LastMatchLength = currentChain.length;
            currentChain.forEach(tile => {
                var curTile = this.Tiles[tile.CurrentTile];
                var thisIdx = this.AllCells.indexOf(tile);
                if (tile.CurrentTileFlipped || (curTile && curTile.Front === curTile.Back)) {
                    tile.CurrentTile = -1;
                    tile.Color = '';
                    tile.CurrentTileFlipped = false;
                    if (this.ToBeActive.includes(thisIdx)) {
                        this.ToBeActive.splice(this.ToBeActive.indexOf(thisIdx), 1);
                    }
                    if (thisIdx !== idx && this.CurrentlyActive.includes(thisIdx)) {
                        this.CurrentlyActive.splice(this.CurrentlyActive.indexOf(thisIdx), 1);
                    }
                } else {
                    tile.CurrentTileFlipped = true;
                    if (curTile) {
                        curTile.Flipped = true;
                        tile.Color = curTile.Back;
                    } else {
                        switch (tile.Color) {
                            case "Blue":
                                BlueTile.Flipped = true;
                                break;
                            case "Green":
                                GreenTile.Flipped = true;
                                break;
                            case "Yellow":
                                YellowTile.Flipped = true;
                                break;
                            case "Orange":
                                OrangeTile.Flipped = true;
                                break;
                            case "Red":
                                RedTile.Flipped = true;
                                break;
                            case "Purple":
                                PurpleTile.Flipped = true;
                                break;
                        }
                    }
                    this.ToBeActive.push(thisIdx);
                }
            })
            return true;
        }
        return false;
    },

    GetChain(cell, existing) {
        var nextStep = this.GetAdjacentMatchingCells(cell).filter(c => !existing.includes(c));
        var result = existing;
        result.push(cell);
        nextStep.forEach(c =>
        {
            if (!result.includes(c))
            {
                result = this.GetChain(c, result);
            }
        });
        return result;
    },

    GetAdjacentMatchingCells(cell) {
        let result: CellModel[] = [];
        cell.AdjacentTiles.forEach(t => {
            if (this.AllCells[t].Color === cell.Color)
            {
                result.push(this.AllCells[t]);
            }
        });
        return result;
    }
};