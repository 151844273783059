const SurviveHelpArea = (props: any) => {
    return (
        <div className="center" style={{display:"block", clear:"both", textAlign:"left", color:"black", width: window.innerWidth / 2}}>
            Click on any empty cell to place the tile. <br />
            When 3 or more tiles are grouped together of the same color, a match is resolved. <br />
            All tiles in the match with a bee on them will flip over. The back side of the tile could be any color. <br />
            All tiles without a bee will be removed from the board. <br />
            Some tile's don't have a back. They will be removed automatically <br />
            If the board is full, the game ends. <br />
            If a tile is removed from any of the 6 corners of the board, the game ends. <br />
            If all 66 tiles are successfully played to the board, the game ends. <br />
            When the game ends, you will be given a score: <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 points for every tile that has been removed from the board <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 points for every bee in a corner of the board <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 point for every additional tile on the board without a bee <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-10 points for every empty corner <br />
        </div>
    );
};

export default SurviveHelpArea;