import { Route, Switch } from "react-router-dom";
import Game from "./pages/Game";
import NotMatch from "./pages/NotMatch";
import Survival from "./pages/Survival";

function App() {
  return (
    <>
    <Switch>
        <Route path="/Survive" component={Survival} />
        <Route path="/" component={Game} />
        <Route path="*">
            <NotMatch />
        </Route>
    </Switch>
    </>
  );
}

export default App;
