import React from "react";
import DamageAlert from "../components/Damage/DamageAlert";
import SurviveHelpArea from "../components/Help/SurviveHelpArea";
import HexCell from "../components/Hexes/HexCell";
import { BlueTile, Controller, GreenTile, OrangeTile, PurpleTile, RedTile, TileModel, YellowTile } from "../features/Controller";

interface SurvivalState {
    Tiles: TileModel[],
    TilesPlayed: number,
    Score: number,
    GameOver: boolean,
    WindowWidth: number,
    WindowHeight: number
}

const NullTile: TileModel = {
    Front: '',
    Back: '',
    Flipped: false
}

class Survival extends React.Component<any, SurvivalState, any> {
    clickedIdx: number = -1;
    isScoring: boolean = false;
    constructor(props: any) {
        super(props);
        if (!this.state) {
            this.state = {
                Tiles: [BlueTile,NullTile,NullTile,GreenTile,NullTile,PurpleTile,NullTile,NullTile,NullTile,NullTile,NullTile,NullTile,NullTile,YellowTile,NullTile,RedTile,NullTile,NullTile,OrangeTile],
                TilesPlayed: 6,
                Score: 0,
                GameOver: false,
                WindowWidth: window.innerWidth,
                WindowHeight: window.innerHeight
            }
        }
        Controller.AllCells.forEach(cell => {
            if (cell.CurrentTile > -1) {
                cell.CurrentTile = -2;
            }
        });
        document.title = 'Honey Survival';
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize.bind(this))
    }

    render() {
        return (
            <>
            <div className="board">
            <div className="board-row">
                <HexCell tile={this.state.Tiles[0]} index={0} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[1]} index={1} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[2]} index={2} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[3]} index={3} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[4]} index={4} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[5]} index={5} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[6]} index={6} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[7]} index={7} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[8]} index={8} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[9]} index={9} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[10]} index={10} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[11]} index={11} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[12]} index={12} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[13]} index={13} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[14]} index={14} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[15]} index={15} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[16]} index={16} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[17]} index={17} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[18]} index={18} click={this.onClick.bind(this)} />
            </div>
            </div>
            <div style={{height: 100}}>
                <div style={{width: 121, margin: "auto", marginTop: 10}}>
                    <HexCell tile={Controller.Tiles[this.state.TilesPlayed]} active visible={!this.isScoring} />
                </div>
            </div>
            <div style={{width: 121, margin: "auto", marginBottom: 20, textAlign: "center"}}>
                <h1>{Controller.Tiles.length - this.state.TilesPlayed}</h1>
            </div>
            <SurviveHelpArea />
            <DamageAlert score={this.state.Score} gameOver={this.state.GameOver} width={this.state.WindowWidth} height={this.state.WindowHeight} />
            </>
        );
    }

    onClick(idx: number) {
        this.isScoring = true;
        let tiles = this.state.Tiles;
        tiles[idx] = Controller.Tiles[this.state.TilesPlayed];
        Controller.AllCells[idx].CurrentTile = this.state.TilesPlayed;
        Controller.AllCells[idx].Color = tiles[idx].Front;
        this.clickedIdx = idx;
        this.setState({Tiles: tiles, TilesPlayed: this.state.TilesPlayed + 1});
        setTimeout(this.scoreMatch.bind(this), 500);
    }

    scoreMatch() {
        let scored = Controller.ScoreMatch(this.clickedIdx);

        let tiles = this.setEmptyCells();

        if (scored) {
            Controller.CurrentlyActive = [...Controller.ToBeActive];
            Controller.ToBeActive = [];
            setTimeout(this.scoreAgain.bind(this), 500);
        } else {
            if (this.checkForEnd()) {
                this.setState({Score: this.scoreGame(), Tiles: tiles, GameOver: true});
                return;
            }
            else {
                this.isScoring = false;
            }
        }
        this.setState({Tiles: tiles});
    }

    scoreAgain() {
        let scored = false;
        while(!scored && Controller.CurrentlyActive.length > 0) {
            scored = Controller.ScoreMatch(Controller.CurrentlyActive[0]);
            Controller.CurrentlyActive.splice(0, 1);
        }
        if (Controller.CurrentlyActive.length === 0) {
            Controller.CurrentlyActive = [...Controller.ToBeActive];
            Controller.ToBeActive = [];
        }

        let tiles = this.setEmptyCells();

        if (Controller.CurrentlyActive.length > 0) {
            setTimeout(this.scoreAgain.bind(this), 500);
        } else {
            if (this.checkForEnd()) {
                this.setState({Score: this.scoreGame(), Tiles: tiles, GameOver: true});
                return;
            }
            else {
                this.isScoring = false;
            }
        }
        this.setState({Tiles: tiles});
    }

    setEmptyCells() {
        let tiles = this.state.Tiles;
        tiles[this.clickedIdx] = Controller.Tiles[this.state.TilesPlayed - 1];
        for (var i in Controller.AllCells) {
            if (Controller.AllCells[i].CurrentTile === -1) {
                tiles[i] = NullTile;
            }
        }

        return tiles;
    }

    checkForEnd() {
        if (this.state.TilesPlayed === 66) return true;
        if (Controller.AllCells.filter(t => t.CurrentTile === -1).length === 0) return true;
        let corners = [0,3,5,13,15,18];
        let cornerGone = false;
        console.log(Controller.AllCells);
        corners.forEach(c => {
            console.log(Controller.AllCells[c]);
            if (Controller.AllCells[c].CurrentTile === -1) cornerGone = true;
        });
        return cornerGone;
    }

    scoreGame() {
        let corners = [0,3,5,13,15,18];
        let score = 2 * (this.state.TilesPlayed - Controller.AllCells.filter(a => a.CurrentTile !== -1).length);
        corners.forEach(c => {
            if (Controller.AllCells[c].CurrentTile !== -1 && !Controller.AllCells[c].CurrentTileFlipped) score += 2;
            if (Controller.AllCells[c].CurrentTile === -1) score -= 10;
        });
        for (let i in Controller.AllCells) {
            if (!corners.includes(parseInt(i)) && Controller.AllCells[i].CurrentTile !== -1 && Controller.AllCells[i].CurrentTileFlipped) {
                score += 1;
            }
        }
        //score -= 1 * (Controller.Tiles.length - this.state.TilesPlayed);
        return score;
    }

    windowResize() {
        this.setState({WindowWidth: window.innerWidth, WindowHeight: window.innerHeight});
    }
}

export default Survival;