import React from "react";
import DamageAlert from "../components/Damage/DamageAlert";
import HandArea from "../components/Hand/HandArea";
import HelpArea from "../components/Help/HelpArea";
import HexCell from "../components/Hexes/HexCell";
import { Controller, TileModel } from "../features/Controller";

interface GameState {
    Tiles: TileModel[],
    TilesDrawn: number,
    Score: number,
    GameOver: boolean,
    WindowWidth: number,
    WindowHeight: number
}

const NullTile: TileModel = {
    Front: '',
    Back: '',
    Flipped: false
}

class Game extends React.Component<any, GameState, any> {
    clickedIdx: number = -1;
    isScoring: boolean = false;
    handSize: number = 1;
    handTiles: TileModel[] = [];
    selectedCell: number = 0;
    constructor(props: any) {
        super(props);
        if (!this.state) {
            this.state = {
                Tiles: [Controller.Tiles[0],NullTile,NullTile,Controller.Tiles[1],NullTile,Controller.Tiles[2],NullTile,NullTile,NullTile,NullTile,NullTile,NullTile,NullTile,Controller.Tiles[3],NullTile,Controller.Tiles[4],NullTile,NullTile,Controller.Tiles[5]],
                TilesDrawn: 7,
                Score: 0,
                GameOver: false,
                WindowWidth: window.innerWidth,
                WindowHeight: window.innerHeight
            }
        }
        this.handTiles.push(Controller.Tiles[6]);
        document.title = 'Honey Clear';
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize.bind(this))
    }

    render() {
        return (
            <>
            <div className="board">
            <div className="board-row">
                <HexCell tile={this.state.Tiles[0]} index={0} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[1]} index={1} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[2]} index={2} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[3]} index={3} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[4]} index={4} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[5]} index={5} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[6]} index={6} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[7]} index={7} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[8]} index={8} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[9]} index={9} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[10]} index={10} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[11]} index={11} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[12]} index={12} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[13]} index={13} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[14]} index={14} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[15]} index={15} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[16]} index={16} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[17]} index={17} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row">
                <HexCell tile={this.state.Tiles[18]} index={18} click={this.onClick.bind(this)} />
            </div>
            </div>
            <div>
                <HandArea tiles={this.handTiles} handSize={this.handSize} selected={this.selectedCell} onClick={this.onClickHand.bind(this)} />
                <div style={{width: 121, margin: "auto", marginBottom: 20, textAlign: "center"}}>
                    <h1>{Controller.Tiles.length - this.state.TilesDrawn}</h1>
                </div>
            </div>
            <HelpArea />
            <DamageAlert score={this.state.Score} gameOver={this.state.GameOver} width={this.state.WindowWidth} height={this.state.WindowHeight} />
            </>
        );
    }

    onClick(idx: number) {
        if (this.handTiles.length > 0) {
            let selection = this.selectedCell;
            if (selection === -1) {
                selection = this.handTiles.length - 1;
            }
            this.isScoring = true;
            let tiles = this.state.Tiles;
            tiles[idx] = this.handTiles[selection];
            Controller.AllCells[idx].CurrentTile = Controller.Tiles.indexOf(this.handTiles[selection]);
            Controller.AllCells[idx].Color = tiles[idx].Front;
            this.handTiles.splice(selection, 1);
            this.clickedIdx = idx;
            this.setState({Tiles: tiles});
            this.drawTile();
            if (this.selectedCell >= this.handTiles.length) this.selectedCell--;
            setTimeout(this.scoreMatch.bind(this), 500);
        }
    }

    onClickHand(idx: number) {
        if (this.handTiles.length > idx) this.selectedCell = idx;
        this.setState({GameOver: false});
    }

    drawTile() {
        if (this.handTiles.length < this.handSize) {
            this.handTiles.push(Controller.Tiles[this.state.TilesDrawn]);
            this.setState({TilesDrawn: this.state.TilesDrawn + 1});
        }
    }

    scoreMatch() {
        let scored = Controller.ScoreMatch(this.clickedIdx);

        let tiles = this.setEmptyCells();

        if (scored) {
            Controller.CurrentlyActive = [...Controller.ToBeActive];
            Controller.ToBeActive = [];
            if (Controller.LastMatchLength > 3 && this.handSize < 12) {
                this.handSize++;
                this.drawTile();
            }
            setTimeout(this.scoreAgain.bind(this), 500);
        } else {
            if (this.checkForEnd()) {
                this.setState({Score: this.scoreGame(), Tiles: tiles, GameOver: true});
                return;
            }
            else {
                this.isScoring = false;
            }
        }
        this.setState({Tiles: tiles});
    }

    scoreAgain() {
        let scored = false;
        while(!scored && Controller.CurrentlyActive.length > 0) {
            scored = Controller.ScoreMatch(Controller.CurrentlyActive[0]);
            Controller.CurrentlyActive.splice(0, 1);
        }
        if (scored && this.handSize < 12) {
            this.handSize++;
            this.drawTile();
        } 
        if (Controller.CurrentlyActive.length === 0) {
            Controller.CurrentlyActive = [...Controller.ToBeActive];
            Controller.ToBeActive = [];
        }

        let tiles = this.setEmptyCells();

        if (Controller.CurrentlyActive.length > 0) {
            setTimeout(this.scoreAgain.bind(this), 500);
        } else {
            if (this.checkForEnd()) {
                this.setState({Score: this.scoreGame(), Tiles: tiles, GameOver: true});
                return;
            }
            else {
                this.isScoring = false;
            }
        }
        this.setState({Tiles: tiles});
    }

    setEmptyCells() {
        let tiles = this.state.Tiles;
        for (var i in Controller.AllCells) {
            if (Controller.AllCells[i].CurrentTile === -1) {
                tiles[i] = NullTile;
            }
        }

        return tiles;
    }

    checkForEnd() {
        if (this.state.TilesDrawn === 66 && this.handTiles.length === 0) return true;
        if (Controller.AllCells.filter(t => t.CurrentTile === -1).length === 0) return true;
        if (Controller.AllCells.filter(t => t.CurrentTile !== -1).length === 0) return true;
        return false;
    }

    scoreGame() {
        let score = 1 * (Controller.Tiles.length - this.state.TilesDrawn);
        score -= this.handSize;

        return score;
    }

    windowResize() {
        this.setState({WindowWidth: window.innerWidth, WindowHeight: window.innerHeight});
    }
}

export default Game;